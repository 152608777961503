<template>
  <span v-if="showMap">
    <!-- <b-modal :id="modalId" centered size="lg" hide-header hide-footer> -->
    <b-row>
      <b-col>
        <b-form-group label="Map" label-for="map-search">
          <b-form-input
            list="datalistAddresses"
            @input="search($event)"
            @change="setPlace($event)"
          />
          <datalist id="datalistAddresses">
            <option v-for="(item, index) in items" :key="index">
              {{ item.ADDRESS }}
            </option>
          </datalist>
          <!-- <b-input-group>
            <GmapAutocomplete class="form-control" @place_changed="setPlace" />

            <b-input-group-append>
              <b-button variant="outline-danger" size="sm" @click="clearSearch">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group> -->
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group>
          <GmapMap
            :center="center"
            :zoom="13"
            map-type-id="terrain"
            style="width: 100%; height: 400px"
          >
            <GmapMarker :title="title" :position="marker" />
          </GmapMap>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- </b-modal> -->
  </span>
</template>

<script>
import axios from '@axios';

export default {
  name: 'Map',
  props: {
    showMap: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: 'modal-map',
    },
  },
  data: () => ({
    center: { lat: 1.29027, lng: 103.851959 },
    marker: { lat: 1.29027, lng: 103.851959 },

    place: null,
    title: null,
    oneMapUrl: `https://www.onemap.gov.sg/api/common/elastic/search?returnGeom=Y&getAddrDetails=Y&searchVal`,
    items: [],
    keyword: '',
  }),
  methods: {
    search(keyword) {
      axios
        .get(`${this.oneMapUrl}=${keyword}`)
        .then(({ data }) => {
          const { results } = data;
          this.items = results;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    setPlace(place) {
      const address = this.items.find((x) => {
        return x.ADDRESS === place;
      });

      if (address) {
        this.place = address;
        this.usePlace();
      }
    },
    usePlace() {
      if (this.place) {
        const {
          ADDRESS,
          LATITUDE,
          LONGITUDE,
          POSTAL,
          BLK_NO,
          ROAD_NAME,
          BUILDING,
        } = this.place;

        this.title = ADDRESS;

        const geoloc = {
          lat: parseFloat(LATITUDE || 0),
          lng: parseFloat(LONGITUDE || 0),
        };

        this.marker = geoloc;
        this.center = geoloc;

        const params = {
          name: `${BLK_NO} ${ROAD_NAME} ${BUILDING}` || '',
          country: 'Singapore',
          postal: POSTAL && POSTAL !== 'NIL' ? POSTAL : '',
          lat: LATITUDE || 0,
          lng: LONGITUDE || 0,
        };

        this.$emit('onChangePlace', params);

        this.place = null;
      }
    },
  },
};
</script>

<style></style>
