<template>
  <span>
    <b-modal :id="modalId" centered :title="options.header" hide-header>
      <b-card-text class="text-center">
        <h3>{{ options.title }}</h3>
        {{ options.body }}
      </b-card-text>

      <template #modal-footer="{ close }">
        <div>
          <b-button
            variant="danger"
            class="bg-darken-4 mr-1"
            size="sm"
            @click="close()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="bg-darken-4"
            size="sm"
            @click="
              close();
              $emit('onChange', true);
            "
          >
            Accept
          </b-button>
        </div>
      </template>
    </b-modal>
  </span>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    modalId: {
      type: String,
      default: 'modal-center',
    },
    options: {
      type: Object,
      default: () => ({
        title: 'Are you sure',
        body: 'are you sure to proceed? Click "Accept" button to continue',
      }),
    },
  },
};
</script>

<style></style>
