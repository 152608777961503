import api from '@apiInstance';

const model = 'joborder_headers';

export default {
  add(data) {
    return api.post(model, data);
  },
  get(id) {
    return api.fetch(`${model}/${id}/detail`);
  },
  list(params) {
    return api.fetch(`/${model}`, { params });
  },
  update(data) {
    return api.patch(`${model}`, data);
  },
  delete(id) {
    return api.remove(`${model}`, id);
  },

  accept(body) {
    return api.request({
      url: `/${model}/` + body.id + `/accept`,
      method: `PATCH`,
      body,
      type: `job-order-accept`,
    });
  },

  updatejobstatus(body) {
    return api.request({
      url: `/${model}/` + body.id + `/updatejobstatus`,
      method: `PATCH`,
      body,
      type: `job-order-updatestatus`,
    });
  },

  generatepdf(id) {
    return api.fetch(`${model}/${id}/generatepdf`);
  },

  verify(id) {
    return api.fetch(`${model}/${id}/verify`);
  },

  followUp(id) {
    return api.fetch(`${model}/${id}/follow-up`);
  },

  reassign(body) {
    return api.request({
      url: `/${model}/reassign`,
      method: `PATCH`,
      body,
      type: `${model}`,
    });
  },

  serviceReport(body) {
    return api.request({
      url: `/${model}/${body.id}/service-report`,
      method: `POST`,
      body,
      type: `${model}`,
    });
  },

  sendServiceReport(body) {
    return api.request({
      url: `/${model}/send-service-report`,
      method: `POST`,
      body,
      type: `${model}`,
    });
  },

  duplicate(body) {
    return api.request({
      url: `/${model}/${body.from_id}/duplicate`,
      method: `POST`,
      body,
      type: `${model}`,
    });
  },

  getbyjobstatus(params) {
    return api.fetch(`${model}/get-joborders-by-jobstatus`, { params });
  },
};
