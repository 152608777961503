// import api from '@apiInstance';
// import useJwt from '@/auth/jwt/useJwt';
import authApi from '@api/auth';
import { getImage } from '@/global-functions';

export default {
  namespaced: true,
  state: {
    data: {},

    userId: 0,
    companyId: 0,
    authToken: '',
  },
  getters: {},
  mutations: {
    AUTHENTICATING_SUCCESS(state, payload) {
      authApi.setToken(payload.token);
      state.userId = payload.user_id;
      state.companyId = payload.company_id;
      state.authToken = payload.token;
      if (process.browser) {
        window.localStorage.setItem('userId', payload.user_id);
      }
    },
    AUTHENTICATING_ERROR(state) {
      state.userId = 0;
      state.companyId = 0;
      state.authToken = '';
      authApi.removeToken();
      if (process.browser) {
        window.localStorage.clear();
      }
    },

    AUTHENTICATE(state, payload) {
      state.data = payload;
      const { token, refresh_token } = payload;
      const user = payload?.user?.data;
      if (user) {
        const userData = {
          id: user.id,
          fullName: `${user.first_name} ${user.last_name}`,
          firstName: user.first_name,
          lastName: user.last_name,
          companyId: user.company_id,
          username: user.username,
          avatar: getImage({
            model: 'users',
            id: user.id,
            thumbnail: user.thumbnail,
          }),
          email: user.email,
          status: user.status,
          role: payload.role,
          ability: payload.ability,
          uuid: user.uuid,
          token,
          refresh_token,
        };

        window.localStorage.setItem('userData', JSON.stringify(userData));

        console.log(payload);

        // useJwt.setToken(payload.token);
        // useJwt.setRefreshToken(payload.refresh_token);
      }

      authApi.setToken(payload.token);
    },
  },
  actions: {
    authenticate({ commit }, payload) {
      if (payload) {
        commit('AUTHENTICATE', payload);
      }
    },

    login({ commit }, payload) {
      return authApi
        .login(payload)
        .then((res) => commit('AUTHENTICATING_SUCCESS', res.data))
        .catch((err) => {
          commit('AUTHENTICATING_ERROR');
          return Promise.reject(err);
        });
    },
    logout({ commit }) {
      return authApi
        .logout()
        .then(() => commit('AUTHENTICATING_ERROR'))
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    socialCallback({ commit }, payload) {
      return authApi
        .socialCallback(payload)
        .then((res) => commit('AUTHENTICATING_SUCCESS', res.data))
        .catch((err) => {
          commit('AUTHENTICATING_ERROR');
          return Promise.reject(err);
        });
    },
    removeSession({ commit }) {
      commit('AUTHENTICATING_ERROR');
    },
    refreshToken({ commit }) {
      return authApi
        .refreshToken()
        .then((res) => commit('AUTHENTICATING_SUCCESS', res.data))
        .catch((err) => {
          commit('AUTHENTICATING_ERROR');
          return Promise.reject(err);
        });
    },
  },
};
