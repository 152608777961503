import Vue from 'vue';

// axios
import axios from 'axios';

const axiosIns = axios.create({
  // baseURL: process.env.VUE_APP_ROOT_API_URL,
  // timeout: 2500,
  // headers: { "X-Requested-With": "XMLHttpRequest" },
  // Authorization: "",
});

Vue.prototype.$http_axios = axiosIns;

export default axiosIns;
