export default [
  {
    path: '/pages/masterlists/customers',
    name: 'customers',
    component: () => import('@/views/pages/masterlists/customers/index'),
    meta: {
      resource: 'Customers', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/customers/manage',
    name: 'customers-manage',
    component: () => import('@/views/pages/masterlists/customers/manage'),
    meta: {
      resource: 'Customers Manage', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/customers/view',
    name: 'customers-view',
    component: () => import('@/views/pages/masterlists/customers/view'),
    meta: {
      resource: 'Customers View', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/employee',
    name: 'employee',
    component: () => import('@/views/pages/masterlists/employee/index'),
    meta: {
      resource: 'Employee', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/supplier',
    name: 'supplier',
    component: () => import('@/views/pages/masterlists/supplier/index'),
    meta: {
      resource: 'Supplier', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/material-warehouse',
    name: 'material-warehouse',
    component: () =>
      import('@/views/pages/masterlists/material-warehouse/index'),
    meta: {
      resource: 'Material Warehouse', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/services',
    name: 'services',
    component: () => import('@/views/pages/masterlists/services/index'),
    meta: {
      resource: 'Services', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/brands',
    name: 'brands',
    component: () => import('@/views/pages/masterlists/brands/index'),
    meta: {
      resource: 'Brands', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/contract-types',
    name: 'contract-types',
    component: () => import('@/views/pages/masterlists/contract-types/index'),
    meta: {
      resource: 'Contract Types', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/company-groups',
    name: 'company-groups',
    component: () => import('@/views/pages/masterlists/company-groups/index'),
    meta: {
      resource: 'Company Groups', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/module',
    name: 'modules',
    component: () => import('@/views/pages/masterlists/modules/index'),
    meta: {
      resource: 'Module', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/company-types',
    name: 'company-types',
    component: () => import('@/views/pages/masterlists/company-types/index'),
    meta: {
      resource: 'CompanyTypes', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/competencies',
    name: 'competencies',
    component: () => import('@/views/pages/masterlists/competencies/index'),
    meta: {
      resource: 'Competency', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/uom',
    name: 'uom',
    component: () => import('@/views/pages/masterlists/uom/index'),
    meta: {
      resource: 'UOM', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/groups',
    name: 'groups',
    component: () => import('@/views/pages/masterlists/groups/index'),
    meta: {
      resource: 'Groups', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/country-codes',
    name: 'country-codes',
    component: () => import('@/views/pages/masterlists/country-codes/index'),
    meta: {
      resource: 'CountryCodes', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/gsts',
    name: 'gsts',
    component: () => import('@/views/pages/masterlists/gsts/index'),
    meta: {
      resource: 'GSTs', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/currencies',
    name: 'currencies',
    component: () => import('@/views/pages/masterlists/currencies/index'),
    meta: {
      resource: 'GSTs', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/exchange-rates',
    name: 'exchange-rates',
    component: () => import('@/views/pages/masterlists/exchange-rates/index'),
    meta: {
      resource: 'GSTs', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/jobs',
    name: 'jobs',
    component: () => import('@/views/pages/masterlists/jobs/index'),
    meta: {
      resource: 'Jobs', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/jobs/manage',
    name: 'jobs-manage',
    component: () => import('@/views/pages/masterlists/jobs/manage'),
    meta: {
      resource: 'Jobs Manage', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/jobs/view',
    name: 'jobs-view',
    component: () => import('@/views/pages/masterlists/jobs/view'),
    meta: {
      resource: 'Jobs View', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/designations',
    name: 'designations',
    component: () => import('@/views/pages/masterlists/designations'),
    meta: {
      resource: 'Designations', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/departments',
    name: 'departments',
    component: () => import('@/views/pages/masterlists/departments'),
    meta: {
      resource: 'Departments', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/document-types',
    name: 'document-types',
    component: () => import('@/views/pages/masterlists/document-types'),
    meta: {
      resource: 'Document Types', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/statuses',
    name: 'statuses',
    component: () => import('@/views/pages/masterlists/statuses'),
    meta: {
      resource: 'Statuses', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/trainings',
    name: 'trainings',
    component: () => import('@/views/pages/masterlists/trainings/index'),
    meta: {
      resource: 'Trainings', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/trainings/manage',
    name: 'trainings-manage',
    component: () => import('@/views/pages/masterlists/trainings/manage'),
    meta: {
      resource: 'Trainings', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/trainings/view',
    name: 'trainings-view',
    component: () => import('@/views/pages/masterlists/trainings/view'),
    meta: {
      resource: 'Trainings', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/training-management',
    name: 'training-management',
    component: () =>
      import('@/views/pages/masterlists/training-management/index'),
    meta: {
      resource: 'Training Management', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/training-management/manage',
    name: 'trainings-management-manage',
    component: () =>
      import('@/views/pages/masterlists/training-management/manage'),
    meta: {
      resource: 'Trainings', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/training-management/view',
    name: 'trainings-management-view',
    component: () =>
      import('@/views/pages/masterlists/training-management/view'),
    meta: {
      resource: 'Trainings', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/training-subjects',
    name: 'training-subjects',
    component: () =>
      import('@/views/pages/masterlists/training-subjects/index'),
    meta: {
      resource: 'Training Subjects', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/training-partners',
    name: 'training-partners',
    component: () =>
      import('@/views/pages/masterlists/training-partners/index'),
    meta: {
      resource: 'Training Partners', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/relocation-categories',
    name: 'relocation-categories',
    component: () =>
      import('@/views/pages/masterlists/relocation-categories/index'),
    meta: {
      resource: 'Relocation Categories', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/masterlists/relocation-item-masters',
    name: 'relocation-item-masters',
    component: () =>
      import('@/views/pages/masterlists/relocation-item-masters/index'),
    meta: {
      resource: 'Relocation Item Masters', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/relocation-item-masters/manage',
    name: 'relocation-item-masters-manage',
    component: () =>
      import('@/views/pages/masterlists/relocation-item-masters/manage'),
    meta: {
      resource: 'Relocation Item Masters', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/masterlists/relocation-item-masters/view',
    name: 'relocation-item-masters-view',
    component: () =>
      import('@/views/pages/masterlists/relocation-item-masters/view'),
    meta: {
      resource: 'Relocation Item Masters', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
];
