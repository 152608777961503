import api from '@apiInstance';

export default {
  add (body) {
    return api.post('statuses', body);
  },
  get (id) {
    return api.fetch('statuses/' + id);
  },
  list (params) {
    return api.fetch('/statuses', { params });
  },
  update (body) {
    return api.patch('statuses', body);
  },
  delete (id) {
    return api.remove('statuses', id);
  }
};
