import Vue from 'vue';
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxPaging,
  DxPager,
  DxEditing,
  DxLookup,
  DxSearchPanel,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxFilterRow,
  DxSorting,
  DxRowDragging,
  DxSelection,
  DxColumnChooser,
  DxColumnFixing,
  DxSummary,
  DxGroupItem,
  DxSortByGroupSummaryInfo,
  DxButton,
  DxMasterDetail,
  DxExport,
  DxToolbar,
  DxItem,
} from 'devextreme-vue/data-grid';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxProgressBar } from 'devextreme-vue/progress-bar';
import { DxPivotGrid, DxFieldChooser } from 'devextreme-vue/pivot-grid';
import { DxScheduler, DxResource } from 'devextreme-vue/scheduler';

const devextreme = [
  { tag: 'DxDataGrid', component: DxDataGrid },
  { tag: 'DxColumn', component: DxColumn },
  { tag: 'DxScrolling', component: DxScrolling },
  { tag: 'DxPaging', component: DxPaging },
  { tag: 'DxPager', component: DxPager },
  { tag: 'DxEditing', component: DxEditing },
  { tag: 'DxLookup', component: DxLookup },
  { tag: 'DxSearchPanel', component: DxSearchPanel },
  { tag: 'DxGrouping', component: DxGrouping },
  { tag: 'DxGroupPanel', component: DxGroupPanel },
  { tag: 'DxHeaderFilter', component: DxHeaderFilter },
  { tag: 'DxFilterRow', component: DxFilterRow },
  { tag: 'DxSorting', component: DxSorting },
  { tag: 'DxRowDragging', component: DxRowDragging },
  { tag: 'DxSelection', component: DxSelection },
  { tag: 'DxColumnChooser', component: DxColumnChooser },
  { tag: 'DxColumnFixing', component: DxColumnFixing },
  { tag: 'DxSummary', component: DxSummary },
  { tag: 'DxGroupItem', component: DxGroupItem },
  { tag: 'DxSortByGroupSummaryInfo', component: DxSortByGroupSummaryInfo },
  { tag: 'DxButton', component: DxButton },
  { tag: 'DxMasterDetail', component: DxMasterDetail },
  { tag: 'DxExport', component: DxExport },
  { tag: 'DxToolbar', component: DxToolbar },
  { tag: 'DxItem', component: DxItem },
  { tag: 'DxSelectBox', component: DxSelectBox },
  { tag: 'DxTextArea', component: DxTextArea },
  { tag: 'DxFileUploader', component: DxFileUploader },
  { tag: 'DxProgressBar', component: DxProgressBar },
  { tag: 'DxPivotGrid', component: DxPivotGrid },
  { tag: 'DxFieldChooser', component: DxFieldChooser },
  { tag: 'DxScheduler', component: DxScheduler },
  { tag: 'DxResource', component: DxResource },
];

devextreme.forEach((e) => {
  Vue.component(e.tag, e.component);
});
