<template>
  <span>
    <b-row>
      <b-col>
        <b-form-group>
          <DxDataGrid
            key-expr="id"
            :data-source="dataSource"
            :focused-row-enabled="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-borders="true"
            :row-alternation-enabled="true"
          >
            <DxPaging :page-size="totalCount" />

            <DxColumn
              v-for="(item, index) in dataColumns"
              :key="index"
              :data-field="item.field"
              :caption="item.caption"
            />

            <DxColumnChooser :enabled="true" />
            <DxColumnFixing :enabled="true" />
            <DxSorting mode="multiple" />
            <DxFilterRow :visible="true" />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="false" />
            <DxHeaderFilter :visible="true" />
            <DxSearchPanel location="before" :visible="true" />

            <DxToolbar>
              <DxItem location="before" name="columnChooserButton" />
              <DxItem location="before" name="searchPanel" />
              <DxItem name="groupPanel" />

              <DxItem template="refreshBtnTemplate" />
            </DxToolbar>

            <template #refreshBtnTemplate>
              <div>
                <b-button variant="primary" @click="loadTimelineHeader()">
                  <feather-icon icon="RefreshCwIcon" />
                </b-button>
              </div>
            </template>
          </DxDataGrid>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-nav>
          <b-nav-item
            @click="onSetPage(page)"
            v-for="(page, index) in pageSize"
            :key="index"
          >
            <span :class="page.isActive ? 'text-success' : ''">
              {{ page.name }}
            </span>
          </b-nav-item>
        </b-nav>
      </b-col>
      <b-col>
        <b-pagination
          align="right"
          v-model="currentPage"
          :total-rows="totalCount"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        />
      </b-col>
    </b-row>
  </span>
</template>

<script>
import timelineHeaderApi from '@api/timeline_headers';
import timelineApi from '@api/timelines';
import { queryParameters } from '@/schema';

export default {
  name: 'Comments',
  props: {
    module: {
      type: String,
      default: '',
    },
    headerId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    currentPage: 1,
    perPage: 0,
    pageSize: [
      { name: '10', value: 10, isActive: false },
      { name: '20', value: 20, isActive: false },
      { name: '50', value: 50, isActive: false },
      { name: '100', value: 100, isActive: false },
      { name: 'ALL', value: 10000, isActive: false },
    ],
    totalCount: 0,
    page: 0,
    size: 0,
    dataSource: [],
    dataColumns: [
      { field: 'user_id', caption: 'Name' },
      { field: 'remarks', caption: 'Remarks' },
      { field: 'action_datetime', caption: 'Date/Time' },
    ],
  }),
  watch: {
    currentPage(v) {
      this.page = v;
      this.loadTimelineHeader();
    },
  },
  created() {
    this.onSetPage({ value: queryParameters.page.size });
  },
  mounted() {
    if (+this.$route.query.id) {
      this.loadTimelineHeader();
    }
  },
  methods: {
    onDelete(data) {
      console.log(data);
    },
    loadTimelineHeader() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 10 },
        status: queryParameters.status,
        filterExt: `module='${this.module}',header_type='comment'`,
      };

      if (this.headerId) {
        params.filterExt += `,header_id=${this.headerId}`;
      } else {
        params.filterExt += `,header_id=${this.$route.query.id}`;
      }

      timelineHeaderApi
        .list(params)
        .then(({ data }) => {
          if (data && data.length > 0) {
            this.loadTimelines(data[0].id);
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadTimelines(id) {
      const params = {
        sort: '-updated_at',
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `header=${id}`,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      timelineApi
        .list(params)
        .then(({ data, meta }) => {
          for (const item of data) {
            item.action_datetime = this.displayDateTimeFromUnix(
              item.updated_at
            );
          }
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSetPage(event) {
      for (let i = 0; i < this.pageSize.length; i++) {
        const page = this.pageSize[i];

        page.isActive = event.value === page.value;
      }

      this.perPage = event.value;

      if (event.value !== queryParameters.page.size) {
        this.size = event.value;
        this.loadTimelineHeader();
      }
    },
  },
};
</script>

<style></style>
