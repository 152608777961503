export default [
  {
    path: '/pages/services-tools/task-lists',
    name: 'task-lists',
    component: () => import('@/views/pages/services-tools/task-lists/index'),
    meta: {
      resource: 'Task Lists', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/services-tools/map-view',
    name: 'map-view',
    component: () => import('@/views/pages/services-tools/map-view/index'),
    meta: {
      resource: 'Map View', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/services-tools/calendar',
    name: 'calendar',
    component: () => import('@/views/pages/services-tools/calendar/index'),
    meta: {
      resource: 'Calendar', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/services-tools/service-request',
    name: 'service-request',
    component: () =>
      import('@/views/pages/services-tools/service-request/index'),
    meta: {
      resource: 'Service Request', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/services-tools/service-request/manage',
    name: 'service-request-manage',
    component: () =>
      import('@/views/pages/services-tools/service-request/manage'),
    meta: {
      resource: 'Service Request', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/services-tools/service-request/view',
    name: 'service-request-view',
    component: () =>
      import('@/views/pages/services-tools/service-request/view'),
    meta: {
      resource: 'Service Request', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/services-tools/job-order',
    name: 'job-order',
    component: () => import('@/views/pages/services-tools/job-order/index'),
    meta: {
      resource: 'Job Order', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/services-tools/job-order/manage',
    name: 'job-order-manage',
    component: () => import('@/views/pages/services-tools/job-order/manage'),
    meta: {
      resource: 'Job Order', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/services-tools/job-order/view',
    name: 'job-order-view',
    component: () => import('@/views/pages/services-tools/job-order/view'),
    meta: {
      resource: 'Job Order', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/services-tools/service-report',
    name: 'service-report',
    component: () =>
      import('@/views/pages/services-tools/service-report/index'),
    meta: {
      resource: 'Service Report', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/services-tools/service-report/manage',
    name: 'service-report-manage',
    component: () =>
      import('@/views/pages/services-tools/service-report/manage'),
    meta: {
      resource: 'Job Order', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/services-tools/service-report/view',
    name: 'service-report-view',
    component: () => import('@/views/pages/services-tools/service-report/view'),
    meta: {
      resource: 'Service Report', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/services-tools/job-relocation',
    name: 'job-relocation',
    component: () =>
      import('@/views/pages/services-tools/job-relocation/index'),
    meta: {
      resource: 'Job Relocation', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/services-tools/job-relocation/manage',
    name: 'job-relocation-manage',
    component: () =>
      import('@/views/pages/services-tools/job-relocation/manage'),
    meta: {
      resource: 'Job Relocation', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/services-tools/job-relocation/view',
    name: 'job-relocation-view',
    component: () => import('@/views/pages/services-tools/job-relocation/view'),
    meta: {
      resource: 'Job Relocation', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
];
