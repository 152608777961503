export default [
  {
    path: '/pages/admin/timeline',
    name: 'timeline',
    component: () => import('@/views/pages/admin/timeline/index'),
    meta: {
      resource: 'Timeline', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },
  {
    path: '/pages/admin/clocking',
    name: 'clocking',
    component: () => import('@/views/pages/admin/clocking/index'),
    meta: {
      resource: 'Clocking', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },
  {
    path: '/pages/admin/blogs',
    name: 'blogs',
    component: () => import('@/views/pages/admin/blogs/index'),
    meta: {
      resource: 'Blogs', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },
  {
    path: '/pages/admin/announcements',
    name: 'announcements',
    component: () => import('@/views/pages/admin/announcements/index'),
    meta: {
      resource: 'Announcements', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },
  {
    path: '/pages/admin/news',
    name: 'news',
    component: () => import('@/views/pages/admin/news/index'),
    meta: {
      resource: 'News', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },
  {
    path: '/pages/admin/system-pages',
    name: 'system-pages',
    component: () => import('@/views/pages/admin/system-pages/index'),
    meta: {
      resource: 'System Pages', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },
  {
    path: '/pages/admin/audit-logs',
    name: 'audit-logs',
    component: () => import('@/views/pages/admin/audit-logs/index'),
    meta: {
      resource: 'Audit Logs', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },
  {
    path: '/pages/admin/reports',
    name: 'reports',
    component: () => import('@/views/pages/admin/reports/Reports'),
    meta: {
      resource: 'Reports', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },
  {
    path: '/pages/admin/reports/view',
    name: 'reports-view',
    component: () => import('@/views/pages/admin/reports/FullView'),
    meta: {
      resource: 'Reports', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },

  {
    path: '/pages/admin/users',
    name: 'users',
    component: () => import('@/views/pages/admin/users/index'),
    meta: {
      resource: 'Users', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },

  {
    path: '/pages/admin/sponsee',
    name: 'user-memberships',
    component: () => import('@/views/pages/admin/user-memberships/index'),
    meta: {
      resource: 'Sponsee', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },
  {
    path: '/pages/admin/sponsee/view',
    name: 'user-memberships-view',
    component: () => import('@/views/pages/admin/user-memberships/View'),
    meta: {
      resource: 'Sponsee View', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },

  {
    path: '/pages/admin/useravailabilities',
    name: 'user-availabilities',
    component: () => import('@/views/pages/admin/user-availabilities/index'),
    meta: {
      resource: 'User Availabilities', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },

  {
    path: '/pages/admin/ticketmonitoring',
    name: 'ticket-monitoring',
    component: () => import('@/views/pages/admin/ticket-monitoring/index'),
    meta: {
      resource: 'Ticket Monitoring', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },

  {
    path: '/pages/admin/ticketmonitoringpotential',
    name: 'ticket-monitoring-potential',
    component: () => import('@/views/pages/admin/ticket-monitoring-potential/index'),
    meta: {
      resource: 'Ticket Monitoring', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },

  {
    path: '/pages/admin/ticketmonitoringpreventive',
    name: 'ticket-monitoring-preventive',
    component: () => import('@/views/pages/admin/ticket-monitoring-preventive/index'),
    meta: {
      resource: 'Ticket Monitoring', // page name or all
      action: 'manage' // create, read, update, delete and manage
    }
  },
];
