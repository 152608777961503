export default [
  {
    path: '/pages/agreements/contracts',
    name: 'contracts',
    component: () => import('@/views/pages/agreements/contracts/index'),
    meta: {
      resource: 'Contracts', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/agreements/contracts/manage',
    name: 'contracts-manage',
    component: () => import('@/views/pages/agreements/contracts/manage'),
    meta: {
      resource: 'Contracts Manage', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/agreements/contracts/view',
    name: 'contracts-view',
    component: () => import('@/views/pages/agreements/contracts/view'),
    meta: {
      resource: 'Contracts View', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/agreements/warranty',
    name: 'warranty',
    component: () => import('@/views/pages/agreements/warranty/index'),
    meta: {
      resource: 'Warranty', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/agreements/warranty/manage',
    name: 'warranty-manage',
    component: () => import('@/views/pages/agreements/warranty/manage'),
    meta: {
      resource: 'Warranty Manage', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/agreements/warranty/view',
    name: 'warranty-view',
    component: () => import('@/views/pages/agreements/warranty/view'),
    meta: {
      resource: 'Warranty View', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/agreements/renewal',
    name: 'renewal',
    component: () => import('@/views/pages/agreements/renewal/index'),
    meta: {
      resource: 'Renewal', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/agreements/renewal/manage',
    name: 'renewal-manage',
    component: () => import('@/views/pages/agreements/renewal/manage'),
    meta: {
      resource: 'Renewal Manage', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/agreements/renewal/view',
    name: 'renewal-view',
    component: () => import('@/views/pages/agreements/renewal/view'),
    meta: {
      resource: 'Renewal View', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
];
