/* declare functions globally */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
// import axios from '@axios';
import { saveAs } from 'file-saver';
import { Blob } from 'buffer';
import Vue from 'vue';

export const parseError = (err) => {
  const errors =
    err && err.response && err.response.data ? err.response.data.errors : [];
  if (errors && errors[0] && errors[0].title) {
    return Object.values(errors);
  }

  return [{ title: 'Unknown Error, Reload the page and try again.' }];
};

export const messageError = (err) => {
  let metaValue;
  const [error] = parseError(err);
  const { title, meta } = error;
  if (meta) {
    [metaValue] = Object.values(meta);
  }

  let msg = title;

  if (metaValue) {
    msg += ` ${metaValue}`;
  }

  return msg;
};

export const moneyFormat = (item) => {
  // const symbol = this.$store.state.currency.current.symbol;
  if (!item) {
    return '0.00';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const formatted = formatter.format(item);

  return `${formatted}`;
};

export const correctMoneyFormat = (value) => {
  const regex = /^[0-9]*$|^[0-9]*\.[0-9]{0,2}$/;
  if (value) {
    const matchedRegex = value.toString().match(regex);

    return matchedRegex != null;
  }
};

export const toastConfig = (msg = '', status = 'success') => {
  const isSuccess = status === 'success';
  const variant = isSuccess ? 'success' : 'danger';
  const icon = isSuccess ? 'CheckCircleIcon' : 'AlertCircleIcon';
  const title = isSuccess ? 'Success' : 'Error';

  if (msg === '') {
    msg = isSuccess ? 'Save Successful' : 'Save Not Successful';
  }

  const data = {
    component: ToastificationContent,
    autoHideDelay: 500,
    position: 'top-right',
    props: {
      title,
      icon,
      variant,
      text: msg,
    },
  };

  Vue.$toast(data);
};

export const openPdf = (byte64, action = 'preview', title = '') => {
  if (byte64) {
    const valFilter = Object.values(byte64).filter((x) => {
      return typeof x === 'string';
    });
    const valJoin = valFilter.join('');

    const byteCharacters = atob(valJoin);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob(
      [byteArray],
      { type: 'application/pdf;base64' },
      'filena.pdf'
    );
    const fileURL = URL.createObjectURL(file);

    if (action === 'print') {
      const time = new Date().getTime();
      title = title ? `${title}` : `${time}`;
      saveAs(file, `${title}.pdf`);
    } else {
      window.open(fileURL);
    }

    // delete data.headers;
    // const body = Object.values(data).join('');
    // const linkSource = `data:application/pdf;base64,${body}`;
    // saveAs(linkSource, 'file.pdf')
  }
};

export const getImage = ({ model, id, thumbnail }) => {
  const url = process.env.VUE_APP_ROOT_STATIC_URL;
  return `${url}/${model}/${id}/${thumbnail}`;
};

export const getJobStatus = (data) => {
  let js = '';

  switch (data) {
    case '1':
      js = 'Accepted';
      break;
    case '2':
      js = 'Work in progress';
      break;
    case '3':
      js = 'Paused';
      break;
    case '4':
      js = 'Done';
      break;
    case '5':
      js = 'Completed';
      break;

    default:
      js = 'New';
      break;
  }

  return js;
};

export const getAddress = (value) => {
  const { addr1, addr2, country, postal } = value;
  let address = addr1;

  if (addr2) {
    address += ` ${addr2}`;
  }

  if (country) {
    address += ` ${country}`;
  }

  if (postal) {
    address += ` ${postal}`;
  }

  return address.toLowerCase();
};

const formatLocaleDate = (date, locale = 'en-CA') => {
  return date
    .toLocaleDateString(locale, {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h24',
    })
    .replace(', ', 'T')
    .replace('24:', '00:');
};

export const inputDateTimeToLocale = (date = new Date(), locale = 'en-CA') => {
  return formatLocaleDate(date, locale);
};

export const inputDateTimeToUnix = (date) => {
  if (date) {
    return Math.floor(new Date(date) / 1000);
  }
};

export const inputDateTimeFromUnix = (unix, locale = 'en-CA') => {
  if (unix) {
    return formatLocaleDate(new Date(unix * 1000), locale);
  }
};

export const inputDateToLocale = () => {
  const [date] = formatLocaleDate(new Date()).split('T');
  return date;
};

export const inputTimeToLocale = () => {
  const [_, time] = formatLocaleDate(new Date()).split('T');
  return time;
};

export const displayDateTimeFromUnix = (unix) => {
  if (!unix) {
    return;
  }

  return new Date(unix * 1000)
    .toLocaleDateString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h12',
    })
    .toUpperCase()
    .replace(',', '');
};

/**
 *
 * @param {number} value
 * @param {string} option day|month|year
 * @param {Date} now new Date()
 * @param {string} lang 'en-GB'|'en-CA'
 * @returns
 */
export const addToDateTime = (
  value,
  option,
  now = new Date(),
  lang = 'en-GB'
) => {
  let output = null;

  if (option === 'day') {
    output = now.setDate(now.getDate() + value);
  }

  if (option === 'month') {
    output = now.setMonth(now.getMonth() + value);
  }

  if (option === 'year') {
    output = now.setFullYear(now.getFullYear() + value);
  }

  if (output) {
    const convertOutput = Math.floor(output / 1000);

    if (lang === 'en-CA') {
      return inputDateTimeFromUnix(convertOutput);
    }

    return displayDateTimeFromUnix(convertOutput);
  }
};

export const subtractToDateTime = (
  value,
  option,
  now = new Date(),
  lang = 'en-GB'
) => {
  let output = null;

  if (option === 'day') {
    output = now.setDate(now.getDate() - value);
  }

  if (option === 'month') {
    output = now.setMonth(now.getMonth() - value);
  }

  if (option === 'year') {
    output = now.setFullYear(now.getFullYear() - value);
  }

  if (output) {
    const convertOutput = Math.floor(output / 1000);

    if (lang === 'en-CA') {
      return inputDateTimeFromUnix(convertOutput);
    }

    return displayDateTimeFromUnix(convertOutput);
  }
};

export const defaultDateTime = (now = new Date()) => {
  const year = now.getFullYear();
  const month = now.getMonth();
  const date = now.getDate();

  const defaultTime = new Date(year, month, date, 8, 0);
  return inputDateTimeToLocale(defaultTime);
};

export const unixToDate = (date) => {
  return new Date(date * 1000);
}

export default {
  moneyFormat,
  correctMoneyFormat,
  toastConfig,
  parseError,
  messageError,
  openPdf,
  getImage,
  getJobStatus,
  getAddress,
  displayDateTimeFromUnix,
  inputDateTimeToLocale,
  inputDateTimeToUnix,
  inputDateTimeFromUnix,
  inputDateToLocale,
  inputTimeToLocale,
  addToDateTime,
  subtractToDateTime,
  defaultDateTime,
  unixToDate
};
