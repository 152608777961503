export default [
  {
    path: '/pages/catalogues/products',
    name: 'products',
    component: () => import('@/views/pages/catalogues/products/index'),
    meta: {
      resource: 'Products', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/catalogues/products/manage',
    name: 'products-manage',
    component: () => import('@/views/pages/catalogues/products/manage'),
    meta: {
      resource: 'Products Manage', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/catalogues/products/view',
    name: 'products-view',
    component: () => import('@/views/pages/catalogues/products/view'),
    meta: {
      resource: 'Products View', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/catalogues/categories',
    name: 'categories',
    component: () => import('@/views/pages/catalogues/categories/index'),
    meta: {
      resource: 'Categories', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/catalogues/product-attributes',
    name: 'product-attributes',
    component: () =>
      import('@/views/pages/catalogues/product-attributes/index'),
    meta: {
      resource: 'Product Attributes', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/catalogues/manufacturers',
    name: 'manufacturers',
    component: () => import('@/views/pages/catalogues/manufacturers/index'),
    meta: {
      resource: 'Manufacturers', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/catalogues/downloadable-catalogues',
    name: 'downloadable-catalogues',
    component: () =>
      import('@/views/pages/catalogues/downloadable-catalogues/index'),
    meta: {
      resource: 'Downloadable Catalogues', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
];
