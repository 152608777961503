import api from '@apiInstance';

export default {
  add(data) {
    return api.post('timeline_headers', data);
  },
  get(id, type) {
    const params = new URLSearchParams({ id, type });

    if (type) {
      return api.fetch(`timeline_headers/getbyheader?${params}`);
    }
    return api.fetch('timeline_headers/' + id);
  },
  list(params) {
    return api.fetch('/timeline_headers', { params });
  },
  update(data) {
    return api.patch('timeline_headers', data);
  },
  delete(id) {
    return api.remove('timeline_headers', id);
  },
};
