import { render, staticRenderFns } from "./CompanyAddressSearch.vue?vue&type=template&id=135bf516&scoped=true"
import script from "./CompanyAddressSearch.vue?vue&type=script&lang=js"
export * from "./CompanyAddressSearch.vue?vue&type=script&lang=js"
import style0 from "./CompanyAddressSearch.vue?vue&type=style&index=0&id=135bf516&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "135bf516",
  null
  
)

export default component.exports