import api from '@apiInstance';

export default {
  add(data) {
    return api.post('companies', data);
  },
  async get(id) {
    const response = await api.fetch(`companies/${id}/detail`);
    return response;
  },
  async list(params) {
    const response = await api.fetch('/companies', { params });
    return response;
  },
  update(data) {
    return api.patch('companies', data);
  },
  delete(id) {
    return api.remove('companies', id);
  },
};
