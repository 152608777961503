<template>
  <span>
    <b-button @click="modalOne = !modalOne" variant="outline-primary" size="sm">
      <feather-icon icon="SearchIcon" /> Search Company
    </b-button>

    <!-- modal 1 -->
    <b-modal v-model="modalOne" size="lg" centered title="Company Search">
      <DxDataGrid
        id="gridContainer"
        ref="dxGridContainer"
        :data-source="dataSource"
        :focused-row-enabled="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        key-expr="id"
        @row-click="selectedCompany($event)"
      >
        <DxScrolling row-rendering-mode="virtual" />
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="[10, 20, 50, 100]"
          display-mode="full"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />

        <DxColumnFixing :enabled="true" />
        <DxSorting mode="multiple" />
        <DxFilterRow :visible="true" />
        <DxGroupPanel :visible="true" />
        <DxGrouping :auto-expand-all="true" />
        <DxHeaderFilter :visible="true" />
        <DxSearchPanel location="before" :visible="true" />

        <DxColumn
          :visible="item.visible"
          :show-in-column-chooser="item.showInColumndChooser"
          :data-field="item.field"
          :caption="item.caption"
          v-for="(item, index) in dataColumns"
          :key="index"
        />

        <DxToolbar>
          <DxItem name="searchPanel" />
        </DxToolbar>
      </DxDataGrid>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button
          :disabled="isLoading"
          @click="onAdhoc()"
          variant="primary"
          class="bg-darken-4"
          size="sm"
        >
          <b-spinner small type="grow" v-if="isLoading"></b-spinner>
          Adhoc
        </b-button>
        <b-button
          :disabled="isLoading"
          @click="modalTwo = !modalTwo"
          variant="primary"
          class="bg-darken-4"
          size="sm"
        >
          <b-spinner small type="grow" v-if="isLoading"></b-spinner>
          More...
        </b-button>
      </template>
    </b-modal>

    <!-- modal 2 -->
    <b-modal v-model="modalTwo" centered size="xl" title="">
      <DxDataGrid
        id="gridContainer"
        ref="dxGridContainer"
        :data-source="dataSource"
        :focused-row-enabled="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        key-expr="id"
        @row-click="selectedCompany($event)"
      >
        <DxScrolling row-rendering-mode="virtual" />
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="[10, 20, 50, 100]"
          display-mode="full"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />

        <DxColumnFixing :enabled="true" />
        <DxSorting mode="multiple" />
        <DxFilterRow :visible="true" />
        <DxGroupPanel :visible="true" />
        <DxGrouping :auto-expand-all="true" />
        <DxHeaderFilter :visible="true" />
        <DxSearchPanel location="before" :visible="true" />

        <DxColumn
          :visible="item.visible"
          :show-in-column-chooser="item.showInColumndChooser"
          :data-field="item.field"
          :caption="item.caption"
          v-for="(item, index) in dataColumns"
          :key="index"
        />

        <DxToolbar>
          <DxItem name="searchPanel" />
        </DxToolbar>
      </DxDataGrid>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button
          :disabled="isLoading"
          @click="modalThree = true"
          variant="primary"
          class="bg-darken-4"
        >
          <b-spinner small type="grow" v-if="isLoading"></b-spinner>
          Add
        </b-button>
      </template>
    </b-modal>

    <!-- modal 3 -->
    <b-modal
      no-close-on-backdrop
      @hide="closeModal()"
      v-model="modalThree"
      centered
      size="lg"
      title="Add Company"
    >
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="Company ID" label-for="v-company_id">
            <b-form-input v-model="formData.company_id" id="v-company_id" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="9">
          <b-form-group label="Company Name" label-for="v-company_name">
            <b-form-input v-model="formData.company_name" id="v-company_name" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="Attn" label-for="v-attn">
            <b-form-input v-model="formData.attn" id="v-attn" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="Email" label-for="v-email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                v-model="formData.email"
                :state="errors.length > 0 ? false : null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="Telephone No." label-for="v-tel">
            <b-form-input v-model="formData.tel" id="v-tel" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="Fax" label-for="v-fax">
            <b-form-input v-model="formData.fax" id="v-fax" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group
            label="Building/Block/House No. and Street Name"
            label-for="v-addr1"
          >
            <b-form-input v-model="formData.addr1" id="v-addr1" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Unit No. and Building Name" label-for="v-addr2">
            <b-form-input v-model="formData.addr2" id="v-addr2" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Country" label-for="v-country">
            <v-select
              id="v-country"
              v-model="formData.country"
              :options="$store.getters['hbaseGeneral/getAllCountries']"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Postal Code" label-for="v-postal">
            <b-form-input v-model="formData.postal" id="v-postal" />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import companiesApi from '@api/companies';
import { queryParameters } from '@/schema';

export default {
  name: 'CompanySearch',
  data: () => ({
    isLoading: false,
    modalOne: false,
    modalTwo: false,
    modalThree: false,
    dataSource: [],
    formData: {
      company_id: null,
      co_type: null,
      company_status: null,
      company_name: null,
      attn: null,
      addr1: null,
      addr2: null,
      postal: null,
      country: 'Singapore',
      tel: null,
      fax: null,
      email: null,
      gst: null,
      gst_percent: null,
      currency_id: null,
    },
    dataColumns: [
      {
        visible: true,
        showInColumndChooser: true,
        caption: 'Company ID',
        field: 'company_id',
      },
      {
        visible: true,
        showInColumndChooser: true,
        caption: 'Company Name',
        field: 'company_name',
      },
      {
        visible: false,
        showInColumndChooser: false,
        caption: 'id',
        field: 'id',
      },
      {
        visible: false,
        showInColumndChooser: false,
        caption: 'type',
        field: 'type',
      },
    ],
  }),
  mounted() {
    this.loadCompanies();
  },
  methods: {
    closeModal() {
      this.modalThree = false;
      this.$nextTick(() => {
        this.formData = Object.assign(
          {},
          {
            company_id: null,
            co_type: null,
            company_status: null,
            company_name: null,
            attn: null,
            addr1: null,
            addr2: null,
            postal: null,
            country: 'Singapore',
            tel: null,
            fax: null,
            email: null,
            gst: null,
            gst_percent: null,
            currency_id: null,
          }
        );
      });
    },
    loadCompanies() {
      this.isLoading = true;
      const params = {
        sort: queryParameters.sort,
        page: { size: 10000 },
        status: queryParameters.status,
      };

      companiesApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectedCompany(e) {
      const data = e?.data;

      if (data) {
        this.$emit('on-select', data);
        this.modalOne = false;
        this.modalTwo = false;
        this.modalThree = false;
      }
    },
    onAdhoc() {
      this.$emit('on-select', {});
      this.modalOne = false;
      this.modalTwo = false;
      this.modalThree = false;
    },
    onSubmit() {
      companiesApi
        .add(this.formData)
        .then(({ data }) => {
          this.$emit('on-select', data);
          this.modalOne = false;
          this.modalTwo = false;
          this.modalThree = false;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style scoped>
.right-side {
  position: absolute;
  right: 1px;
  top: 6px;
}

.logo {
  line-height: 48px;
}

.logo img {
  vertical-align: middle;
  margin: 0 5px;
}

.logo img:first-child {
  margin-right: 9px;
}

.dx-row.dx-data-row .employee {
  color: #bf4e6a;
  font-weight: bold;
}

#gridContainer {
  /* margin: 20px 0; */
  height: 440x;
}
</style>
