import api from '@apiInstance';

export default {
  add (data) {
    return api.post('product', data);
  },
  get (id) {
    return api.fetch('product/' + id);
  },
  async list (params) {
    const response = await api.fetch('/products', { params });
    return response;
  },
  update (data) {
    return api.patch('product', data);
  },
  delete (id) {
    return api.remove('product', id);
  }
};
