<template>
  <span>
    <b-button @click="modalOne = !modalOne" variant="outline-primary" size="sm">
      <feather-icon icon="SearchIcon" /> Search Product
    </b-button>

    <!-- modal 1 -->
    <b-modal v-model="modalOne" size="lg" centered title="Product Search">
      <DxDataGrid
        id="gridContainer"
        ref="dxGridContainer"
        :data-source="dataSource"
        :focused-row-enabled="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        key-expr="id"
        @row-click="selectedProduct($event)"
      >
        <DxScrolling mode="virtual" row-rendering-mode="virtual" />
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="[10, 20, 50, 100]"
          display-mode="full"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />

        <DxColumnFixing :enabled="true" />
        <DxSorting mode="multiple" />
        <DxFilterRow :visible="true" />
        <DxGroupPanel :visible="true" />
        <DxGrouping :auto-expand-all="true" />
        <DxHeaderFilter :visible="true" />
        <DxSearchPanel location="before" :visible="true" />

        <DxColumn data-field="name" caption="Product Name" />

        <DxColumn
          :visible="false"
          :show-in-column-chooser="false"
          data-field="id"
        />

        <DxColumn
          :visible="false"
          :show-in-column-chooser="false"
          data-field="type"
        />

        <DxToolbar>
          <DxItem name="searchPanel" />
        </DxToolbar>
      </DxDataGrid>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button
          :disabled="isLoading"
          @click="modalTwo = !modalTwo"
          variant="primary"
          class="bg-darken-4"
          size="sm"
        >
          <b-spinner small type="grow" v-if="isLoading"></b-spinner>
          More...
        </b-button>
      </template>
    </b-modal>

    <!-- modal 2 -->
    <b-modal v-model="modalTwo" centered size="xl" title="">
      <DxDataGrid
        id="gridContainer"
        ref="dxGridContainer"
        :data-source="dataSource"
        :focused-row-enabled="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        key-expr="id"
        @row-click="selectedProduct($event)"
      >
        <DxScrolling mode="virtual" row-rendering-mode="virtual" />
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="[10, 20, 50, 100]"
          display-mode="full"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />

        <DxColumnFixing :enabled="true" />
        <DxSorting mode="multiple" />
        <DxFilterRow :visible="true" />
        <DxGroupPanel :visible="true" />
        <DxGrouping :auto-expand-all="true" />
        <DxHeaderFilter :visible="true" />
        <DxSearchPanel location="before" :visible="true" />

        <DxColumn data-field="name" caption="Product Name" />
        <DxColumn
          data-field="price"
          caption="Price"
          cell-template="cellPriceTemplate"
        />

        <DxColumn
          :visible="false"
          :show-in-column-chooser="false"
          data-field="id"
        />

        <DxColumn
          :visible="false"
          :show-in-column-chooser="false"
          data-field="type"
        />

        <DxToolbar>
          <DxItem name="searchPanel" />
        </DxToolbar>

        <template #cellPriceTemplate="{ data }">
          {{ moneyFormat(data.value) }}
        </template>
      </DxDataGrid>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button
          :disabled="isLoading"
          @click="modalThree = true"
          variant="primary"
          class="bg-darken-4"
        >
          <b-spinner small type="grow" v-if="isLoading"></b-spinner>
          Add
        </b-button>
      </template>
    </b-modal>

    <!-- modal 3 -->
    <b-modal v-model="modalThree" centered size="lg" title="Add Product">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Product Name" label-for="v-name">
            <b-form-input v-model="formData.name" id="v-name" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="UOM" label-for="v-uom">
            <b-form-input v-model="formData.uom" id="v-uom" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="SKU" label-for="v-sku">
            <b-form-input v-model="formData.sku" id="v-sku" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Barcode" label-for="v-barcode">
            <b-form-input v-model="formData.barcode" id="v-barcode" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Price" label-for="v-price">
            <b-form-input
              v-model.number="formData.price"
              type="number"
              id="v-price"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="New Price" label-for="v-new_price">
            <b-form-input
              v-model.number="formData.new_price"
              type="number"
              id="v-new_price"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Brand" label-for="v-brand">
            <b-form-input v-model="formData.brand" id="v-brand" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Model" label-for="v-model">
            <b-form-input v-model="formData.model" id="v-model" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Stock Quantity" label-for="v-stock_quantity">
            <b-form-input
              v-model.number="formData.stock_quantity"
              type="number"
              id="v-stock_quantity"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Descriptions" label-for="v-description">
            <quill-editor v-model="formData.description" :options="options" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label="Upload" label-for="v-upload">
            <b-form-file
              v-model="fileUpload"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="filePreview">
        <b-col>
          <b-img width="250" center thumbnail fluid :src="filePreview" />
        </b-col>
      </b-row>

      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import productsApi from '@api/products';
import vSelect from 'vue-select';
import { moneyFormat as mf } from '@/global-functions';
import { queryParameters } from '@/schema';

// eslint-disable-next-line
import 'quill/dist/quill.core.css';
// eslint-disable-next-line
import 'quill/dist/quill.snow.css';
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'ProductSearch',
  props: {},
  components: {
    vSelect,

    quillEditor,
  },
  data: () => ({
    isLoading: false,
    modalOne: false,
    modalTwo: false,
    modalThree: false,
    dataSource: [],
    formData: {
      name: null,
      description: null,
      price: 0,
      new_price: 0,
      sku: null,
      uom: null,
      thumbnail: null,
      thumbnailbase64: null,
      brand: null,
      model: null,
      barcode: null,
      stock_quantity: 0,
      status: 'O',
    },
    options: {
      placeholder: 'Product description here...',
    },
    fileUpload: null,
    filePreview: null,
    fileName: null,
  }),
  watch: {
    fileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileName = file.name;
          this.filePreview = reader.result;
        };
      }
    },
  },
  mounted() {
    this.loadProducts();
  },
  methods: {
    closeModal() {
      this.modalThree = false;
      this.$nextTick(() => {
        this.formData = Object.assign(
          {},
          {
            name: null,
            description: null,
            price: 0,
            new_price: 0,
            sku: null,
            uom: null,
            thumbnail: null,
            thumbnailbase64: null,
            brand: null,
            model: null,
            barcode: null,
            stock_quantity: 0,
            status: 'O',
          }
        );
      });
    },
    loadProducts() {
      this.isLoading = true;
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      productsApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectedProduct(e) {
      const data = e?.data;

      if (data) {
        this.$emit('onSelect', data);
        this.modalOne = false;
        this.modalTwo = false;
        this.modalThree = false;
      }
    },
    moneyFormat(data) {
      return mf(data);
    },
    onSubmit() {
      this.formData.thumbnail = this.fileName;
      this.formData.thumbnailbase64 = this.filePreview;

      productsApi
        .add(this.formData)
        .then(({ data }) => {
          this.$emit('onSelect', data);
          this.modalOne = false;
          this.modalTwo = false;
          this.modalThree = false;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style scoped>
.right-side {
  position: absolute;
  right: 1px;
  top: 6px;
}

.logo {
  line-height: 48px;
}

.logo img {
  vertical-align: middle;
  margin: 0 5px;
}

.logo img:first-child {
  margin-right: 9px;
}

.dx-row.dx-data-row .employee {
  color: #bf4e6a;
  font-weight: bold;
}

#gridContainer {
  /* margin: 20px 0; */
  height: 440x;
}
</style>
