<template>
  <span>
    <b-row>
      <b-col>
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="Tag"
                label-for="v-tag"
              >
                <b-form-input
                  v-model="formData.tag"
                  id="v-tag"
                  placeholder="#..."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="Note"
                label-for="v-notes"
              >
                <quill-editor
                  id="v-notes"
                  ref="quillEditorRef"
                  v-model="content"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="Upload (max. 3 files)"
                label-for="v-upload"
              >
                <b-form-file
                  ref="formFileRef"
                  accept="*"
                  v-model="onFileUpload"
                  placeholder="Choose/Drop a file here..."
                  drop-placeholder="Drop a file here..."
                  id="onFileUploadNotes"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="getImageSource">
            <b-col class="text-center">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label=""
                label-for="v-uploaded_files"
              >
                <b-row>
                  <b-col v-for="(item, index) in getImageSource" :key="index">
                    <div class="">
                      <b-img
                        v-if="item.fileType === 'image'"
                        height="100"
                        width="100"
                        thumbnail
                        fluid
                        :src="item.base64"
                      />
                      <b-img
                        v-if="item.fileType === 'application'"
                        height="100"
                        width="100"
                        thumbnail
                        fluid
                        :src="require('@/assets/document.png')"
                      />
                    </div>
                    <div class="">
                      {{ item.name }}
                    </div>
                    <div class="">
                      <b-button
                        @click="onFileRemove(index)"
                        type="button"
                        variant="outline-danger"
                        size="sm"
                      >
                        Remove
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="text-right">
              <b-button type="submit" variant="primary"> Submit </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <hr />

    <b-row>
      <b-col>
        <h2>Information Trail</h2>
      </b-col>
      <b-col class="text-right">
        <b-form-group>
          <b-input-group size="sm" class="input-group-merge">
            <b-form-input
              v-model="keyword"
              id="Search"
              placeholder="Search..."
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="cursor-pointer" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-for="item in dataSource" :key="item.id" class="mb-3">
      <b-col>
        <div class="">
          <strong>
            {{ item.tag }}
          </strong>
          <span>
            <b-button
              type="button"
              variant="flat-danger"
              size="sm"
              @click="onRemove(item)"
            >
              remove
            </b-button>
          </span>
        </div>
        <div class="p-1">
          <b-row>
            <b-col>
              <b-form-group>
                <span style="display: inline-block">
                  <div>
                    {{ displayDateTimeFromUnix(item.updated_at) }} by
                    {{ item.user_id }}
                  </div>
                  <div v-html="item.notes" />
                </span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              File(s):
              <span
                class="mr-1"
                v-for="image in item.note_attachments.data"
                :key="image.id"
              >
                <a href="javascript:void(0)" @click="openAttachment(image)">
                  {{ image.name }}
                </a>
              </span>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </span>
</template>

<script>
import notesApi from '@api/notes';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { queryParameters } from '@/schema';

export default {
  name: 'Notes',
  props: {
    module: {
      type: String,
      default: '',
    },
    headerId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    headerId(value) {
      if (value) {
        this.loadNotes();
      }
    },
    onFileUpload(file) {
      if (file) {
        if (this.formData.uploads.length > 2) {
          const data = {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Max 3 files`,
              icon: 'XIcon',
              variant: 'danger',
              text: `you have uploaded more than 3 files`,
            },
          };

          this.$toast(data);
          return;
        }

        if (this.formData.uploads.length <= 3) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const { type } = file;
            const data = {
              name: file.name,
              base64: reader.result,
              fileType: type.split('/')[0],
            };

            const isExisting = this.formData.uploads.find((x) => {
              return x.name === file.name;
            });

            if (!isExisting) {
              this.formData.uploads.push(data);
            }
          };
        }
      }
    },
    keyword(v) {
      this.loadNotes();
    },
  },
  computed: {
    getImageSource: {
      get() {
        if (this.headerId) {
          const formData = this.formData;
          if (formData.uploads.length > 0) {
            return formData.uploads;
          }
        }
      },
    },
  },
  data: () => ({
    keyword: null,
    content: null,
    onFileUpload: null,
    dataSource: [],
    items: [],
    formData: {
      id: 0,
      header_id: 0,
      tag: null,
      notes: null,
      uploads: [],
    },
  }),
  methods: {
    loadNotes() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `header_id=${this.headerId},module='${this.module}'`,
      };

      if (this.keyword) {
        params.filter = this.keyword;
      }

      notesApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      let app = null;

      this.formData.notes = this.content;
      this.formData.header_id = parseInt(this.headerId);
      this.formData.module = this.module;

      if (!this.formData.id) {
        app = notesApi.add(this.formData);
      } else {
        app = notesApi.update(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.content = null;
            this.onFileUpload = null;

            this.loadNotes();

            const msg = `${this.formData.tag} success save`;
            this.toastConfig(msg);

            this.formData = {
              id: 0,
              header_id: 0,
              tag: null,
              notes: null,
              uploads: [],
            };
          })
          .catch(() => {
            this.toastConfig('', 'error');
          })
          .finally(() => {
            //
          });
      }
    },
    onFileRemove(index) {
      this.onFileUpload = null;
      this.formData.uploads.splice(index, 1);
    },
    onRemove(data) {
      notesApi
        .delete(data.id)
        .then(({ data }) => {
          this.toastConfig('Delete Successfully');
          this.loadNotes();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    getImage(item) {
      return `${process.env.VUE_APP_ROOT_STATIC_URL}/notes_attachments/${item.id}/${item.file}`;
    },
    openAttachment(item) {
      const url = `${process.env.VUE_APP_ROOT_STATIC_URL}/notes_attachments/${item.id}/${item.file}`;
      window.open(url, '_blank');
    },
    getFileExtension(file) {
      console.log('FILE EXTENSION: ', file);
    },
  },
};
</script>

<style>
p {
  margin: 0 0 0 0;
}
</style>
