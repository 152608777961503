<template>
  <b-row>
    <b-col cols="12">
      <b-alert
        v-if="error && displayError && displayError.title"
        variant="danger"
        show
        dismissible
        @dismissed="dismissed()"
      >
        <div class="alert-body font-small-2">
          <p>
            <feather-icon class="mr-25" icon="AlertOctagonIcon" />
            <small class="mr-50">
              <span class="font-weight-bold">
                {{ displayError.title }}
              </span>
            </small>
          </p>
        </div>
      </b-alert>

      <b-alert
        v-if="success && successMsg"
        variant="success"
        show
        dismissible
        @dismissed="dismissed()"
      >
        <div class="alert-body font-small-2">
          <p>
            <feather-icon class="mr-25" icon="CheckCircleIcon" />
            <small class="mr-50">
              <span class="font-weight-bold">{{ successMsg }}</span>
            </small>
          </p>
        </div>
      </b-alert>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Alert",
  props: {
    error: {
      type: Object,
      default: {},
    },
    success: {
      type: Boolean,
      default: false,
    },
    successMsg: {
      type: String,
      default: "Successfully",
    },
  },
  data: () => ({
    displayError: {
      title: null,
    },
  }),
  watch: {
    error(v) {
      if (v) {
        const errors = v ? v?.response?.data?.errors : null;
        this.displayError = errors ? errors[0] : null;
      } else {
        this.displayError.title = null;
      }
    },
  },
  methods: {
    dismissed() {
      this.displayError = {
        title: null,
      };
    },
  },
};
</script>

<style>
</style>