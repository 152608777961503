<template>
  <span>
    <b-row>
      <b-col cols="12" md="6" v-if="title">
        <h1>{{ title }}</h1>
      </b-col>
      <b-col cols="12" md="6" class="text-right">
        <slot name="action-toolbar" />
      </b-col>
    </b-row>

    <br /><br />
    <b-row>
      <b-col>
        <DxPivotGrid
          ref="grid"
          :data-source="dataSource"
          :allow-sorting-by-summary="true"
          :allow-sorting="true"
          :allow-filtering="true"
          :show-borders="true"
          @exporting="onExporting"
          row-header-layout="tree"
        >
          <DxFieldChooser
            :enabled="true"
            :allow-search="true"
            :apply-changes-mode="applyChangesMode"
          />

          <DxExport :enabled="true" />
        </DxPivotGrid>
      </b-col>
    </b-row>
  </span>
</template>

<script>
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportPivotGrid } from "devextreme/excel_exporter";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";

export default {
  name: "DxPivot",
  props: {
    title: {
      type: String,
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dataSource: {
      fields: [],
    },
    applyChangesMode: "instantly",
  }),
  mounted() {
    if (this.fields.length > 0 && this.items.length > 0) {
      const fields = [];
      this.fields.forEach((item) => {
        fields.push({
          caption: item.name,
          dataField: item.field,
          area: item.area,
          width: 170,
          sortOrder: "desc",
        });
      });

      this.items.forEach((item) => {
        if (item.orderdate > 0) {
          item.orderdate = this.displayDate(item.orderdate);
        }
      });

      this.dataSource = new PivotGridDataSource({
        store: this.items,
        fields: fields,
      });
    }
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Sales");

      exportPivotGrid({
        component: e.component,
        worksheet,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Reports.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
};
</script>

<style>
.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.dx-selectbox {
  margin-top: 5px;
}
</style>