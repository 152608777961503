import api from '@apiInstance';

export default {
  add(params) {
    return api.post('company_locations', params);
  },
  async get(id) {
    const response = await api.fetch('company_locations/' + id);
    return response;
  },
  async list(params) {
    const response = await api.fetch('/company_locations', { params });
    return response;
  },
  update(params) {
    return api.patch('company_locations', params);
  },
  delete(id) {
    return api.remove('company_locations', id);
  },
  setDefault(company_id, id) {
    return api.fetch(`company_locations/${company_id}/${id}/set-default`);
  },
};
