export default {
  namespaced: true,
  state: {
    title: ''
  },
  getters: {
    getTitle: (state) => {
      return state.title;
    }
  },
  mutations: {
    title (state, payload) {
      state.title = payload;
    }
  },
  actions: {
    page ({ commit }, payload) {
      commit('title', payload);
    }
  }
};
