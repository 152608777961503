/* declare components globally can be ('name', component) or (component) */

import Vue from 'vue';

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';

// Added Validation Observer & Validation Provider on global component
import { ValidationProvider, ValidationObserver } from 'vee-validate';

// 3rd Party
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import { quillEditor } from 'vue-quill-editor';

// Added dxgrid on global components
import './devextreme-components';

// SurveyJS
import { Survey } from 'survey-vue-ui';

// user components globally from components folder
import DxGrid from '@/views/components/general/DxGrid';
import DxPivot from '@/views/components/general/DxPivot';
import Modal from '@/views/components/general/Modal';
import Alert from '@/views/components/general/Alert';
import ProductSearch from '@/views/components/general/ProductSearch';
import ModalMap from '@/views/components/general/Map';
import CompanySearch from '@/views/components/general/CompanySearch';
import CompanyAddressSearch from '@/views/components/general/CompanyAddressSearch';
import JobCard from '@/views/components/general/JobCard';
import Notes from '@/views/components/general/Notes';
import Documents from '@/views/components/general/Documents';
import Logs from '@/views/components/general/Logs';
import Comments from '@/views/components/general/Comments';
import ContractsSearch from '@/views/components/general/ContractsSearch';
import RelocationItemSearch from '@/views/components/general/RelocationItemSearch';

import Cleave from 'vue-cleave-component';
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('flat-pickr', flatPickr);
Vue.component('v-select', vSelect);
Vue.component('quill-editor', quillEditor);
Vue.component('Survey', Survey);
Vue.component('DxGrid', DxGrid);
Vue.component('DxPivot', DxPivot);
Vue.component('Modal', Modal);
Vue.component('Alert', Alert);
Vue.component('ProductSearch', ProductSearch);
Vue.component('ModalMap', ModalMap);
Vue.component('CompanySearch', CompanySearch);
Vue.component('CompanyAddressSearch', CompanyAddressSearch);
Vue.component('cleave', Cleave);
Vue.component('JobCard', JobCard);
Vue.component('Notes', Notes);
Vue.component('Documents', Documents);
Vue.component('Logs', Logs);
Vue.component('Comments', Comments);
Vue.component('ContractsSearch', ContractsSearch);
Vue.component('RelocationItemSearch', RelocationItemSearch);
