import api from '@apiInstance';

export default {
  add(types) {
    return api.post('documents', types);
  },
  get(id) {
    return api.fetch(`documents/${id}`);
  },
  list(params) {
    return api.fetch('/documents', { params });
  },
  update(types) {
    return api.patch('documents', types);
  },
  delete(id) {
    return api.remove('documents', id);
  },
};
