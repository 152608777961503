export default [
  {
    path: '/pages/feedbacks/customer-feedbacks',
    name: 'customer-feedbacks',
    component: () => import('@/views/pages/feedbacks/customer-feedbacks/index'),
    meta: {
      resource: 'CustomerFeedbacks', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/feedbacks/customer-feedbacks/manage',
    name: 'customer-manage',
    component: () =>
      import('@/views/pages/feedbacks/customer-feedbacks/manage'),
    meta: {
      resource: 'Customer Feedbacks Manage', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/feedbacks/customer-feedbacks/view',
    name: 'customer-view',
    component: () => import('@/views/pages/feedbacks/customer-feedbacks/view'),
    meta: {
      resource: 'Customer Feedbacks View', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/feedbacks/staff-feedbacks',
    name: 'staff-feedbacks',
    component: () => import('@/views/pages/feedbacks/staff-feedbacks/index'),
    meta: {
      resource: 'Staff Feedbacks', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/feedbacks/staff-feedbacks/manage',
    name: 'staff-feedback-manage',
    component: () => import('@/views/pages/feedbacks/staff-feedbacks/manage'),
    meta: {
      resource: 'Staff Feedbacks Manage', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/feedbacks/staff-feedbacks/view',
    name: 'staff-feedback-view',
    component: () => import('@/views/pages/feedbacks/staff-feedbacks/view'),
    meta: {
      resource: 'Staff Feedbacks View', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/feedbacks/incidents',
    name: 'incidents',
    component: () => import('@/views/pages/feedbacks/incidents/index'),
    meta: {
      resource: 'Incidents', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
];
