export default [
  {
    path: '/pages/sales-tools/rfq',
    name: 'rfq',
    component: () => import('@/views/pages/sales-tools/rfq/index'),
    meta: {
      resource: 'RFQ', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/sales-tools/rfq/manage',
    name: 'rfq-manage',
    component: () => import('@/views/pages/sales-tools/rfq/manage'),
    meta: {
      resource: 'RFQ', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/sales-tools/rfq/view',
    name: 'rfq-view',
    component: () => import('@/views/pages/sales-tools/rfq/view'),
    meta: {
      resource: 'RFQ', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/sales-tools/quotation',
    name: 'quotation',
    component: () => import('@/views/pages/sales-tools/quotation/index'),
  },
  {
    path: '/pages/sales-tools/quotation/manage',
    name: 'quotation-manage',
    component: () => import('@/views/pages/sales-tools/quotation/manage'),
    meta: {
      resource: 'Quotation', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/sales-tools/quotation/view',
    name: 'quotation-view',
    component: () => import('@/views/pages/sales-tools/quotation/view'),
  },

  {
    path: '/pages/sales-tools/sales-order',
    name: 'sales-order',
    component: () => import('@/views/pages/sales-tools/sales-order/index'),
  },
  {
    path: '/pages/sales-tools/sales-order/manage',
    name: 'sales-order-manage',
    component: () => import('@/views/pages/sales-tools/sales-order/manage'),
    meta: {
      resource: 'Sales Order', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/sales-tools/sales-order/view',
    name: 'sales-order-view',
    component: () => import('@/views/pages/sales-tools/sales-order/view'),
  },

  {
    path: '/pages/sales-tools/delivery-order',
    name: 'delivery-order',
    component: () => import('@/views/pages/sales-tools/delivery-order/index'),
  },
  {
    path: '/pages/sales-tools/delivery-order/manage',
    name: 'delivery-order-manage',
    component: () => import('@/views/pages/sales-tools/delivery-order/Manage'),
    meta: {
      resource: 'Delivery Order', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/sales-tools/sales-invoice',
    name: 'sales-invoice',
    component: () => import('@/views/pages/sales-tools/sales-invoice/index'),
  },
  {
    path: '/pages/sales-tools/sales-invoice/manage',
    name: 'sales-invoice-manage',
    component: () => import('@/views/pages/sales-tools/sales-invoice/manage'),
    meta: {
      resource: 'Sales Invoice', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/sales-tools/sales-invoice/view',
    name: 'sales-invoice-view',
    component: () => import('@/views/pages/sales-tools/sales-invoice/view'),
    meta: {
      resource: 'Sales Invoice', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/sales-tools/stock-in-out',
    name: 'stock-in-out',
    component: () => import('@/views/pages/sales-tools/stock-in-out/index'),
  },
  {
    path: '/pages/sales-tools/stock-in-out/manage',
    name: 'stock-in-out-manage',
    component: () => import('@/views/pages/sales-tools/stock-in-out/Manage'),
    meta: {
      resource: 'Stock In/Out', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/sales-tools/supplier-in-out',
    name: 'supplier-in-out',
    component: () => import('@/views/pages/sales-tools/supplier-in-out/index'),
  },
  {
    path: '/pages/sales-tools/supplier-in-out/manage',
    name: 'supplier-in-out-manage',
    component: () => import('@/views/pages/sales-tools/supplier-in-out/Manage'),
    meta: {
      resource: 'Supplier In/Out', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
];
