import api from '@apiInstance';

export default {
  add (body) {
    return api.post('document_types', body);
  },
  get (id) {
    return api.fetch('document_types/' + id);
  },
  list (params) {
    return api.fetch('/document_types', { params });
  },
  update (body) {
    return api.patch('document_types', body);
  },
  delete (id) {
    return api.remove('document_types', id);
  }
};
