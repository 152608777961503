import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

import hbaseGeneral from './hbase-store/general';
import hbasePage from './hbase-store/page';
import hbaseAuth from './hbase-store/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,

    hbaseGeneral,
    hbasePage,
    hbaseAuth
  },
  strict: process.env.DEV
});
