<template>
  <span>
    <b-button @click="modalOne = !modalOne" variant="outline-primary" size="sm">
      <feather-icon icon="SearchIcon" /> Search Relocation Items
    </b-button>

    <!-- modal 1 -->
    <b-modal
      v-model="modalOne"
      size="lg"
      centered
      title="Relocation Item Search"
    >
      <DxDataGrid
        id="gridContainer"
        ref="dxGridContainer"
        :data-source="dataSource"
        :focused-row-enabled="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        key-expr="id"
        @row-click="selectedItem($event)"
      >
        <DxScrolling row-rendering-mode="virtual" />
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="[10, 20, 50, 100]"
          display-mode="full"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />

        <DxColumnFixing :enabled="true" />
        <DxSorting mode="multiple" />
        <DxFilterRow :visible="true" />
        <DxGroupPanel :visible="true" />
        <DxGrouping :auto-expand-all="true" />
        <DxHeaderFilter :visible="true" />
        <DxSearchPanel location="before" :visible="true" />

        <DxColumn
          :visible="item.visible"
          :show-in-column-chooser="item.showInColumndChooser"
          :data-field="item.field"
          :caption="item.caption"
          v-for="(item, index) in dataColumns"
          :key="index"
        />

        <DxToolbar>
          <DxItem name="searchPanel" />
        </DxToolbar>
      </DxDataGrid>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button
          :disabled="isLoading"
          @click="onAdhoc()"
          variant="primary"
          class="bg-darken-4"
          size="sm"
        >
          <b-spinner small type="grow" v-if="isLoading"></b-spinner>
          Adhoc
        </b-button>
        <b-button
          :disabled="isLoading"
          @click="modalTwo = !modalTwo"
          variant="primary"
          class="bg-darken-4"
          size="sm"
        >
          <b-spinner small type="grow" v-if="isLoading"></b-spinner>
          More...
        </b-button>
      </template>
    </b-modal>

    <!-- modal 2 -->
    <b-modal v-model="modalTwo" centered size="xl" title="">
      <DxDataGrid
        id="gridContainer"
        ref="dxGridContainer"
        :data-source="dataSource"
        :focused-row-enabled="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        key-expr="id"
        @row-click="selectedItem($event)"
      >
        <DxScrolling row-rendering-mode="virtual" />
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="[10, 20, 50, 100]"
          display-mode="full"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />

        <DxColumnFixing :enabled="true" />
        <DxSorting mode="multiple" />
        <DxFilterRow :visible="true" />
        <DxGroupPanel :visible="true" />
        <DxGrouping :auto-expand-all="true" />
        <DxHeaderFilter :visible="true" />
        <DxSearchPanel location="before" :visible="true" />

        <DxColumn
          :visible="item.visible"
          :show-in-column-chooser="item.showInColumndChooser"
          :data-field="item.field"
          :caption="item.caption"
          v-for="(item, index) in dataColumns"
          :key="index"
        />

        <DxToolbar>
          <DxItem name="searchPanel" />
        </DxToolbar>
      </DxDataGrid>
    </b-modal>
  </span>
</template>

<script>
import relocationItemsApi from '@api/relocation_item_masters';

export default {
  name: 'CompanySearch',
  data: () => ({
    isLoading: false,
    modalOne: false,
    modalTwo: false,
    modalThree: false,
    dataSource: [],
    dataColumns: [
      {
        visible: true,
        showInColumndChooser: true,
        caption: 'Item Code',
        field: 'item_code',
      },
      {
        visible: true,
        showInColumndChooser: true,
        caption: 'Description',
        field: 'description',
      },
      {
        visible: false,
        showInColumndChooser: false,
        caption: 'id',
        field: 'id',
      },
      {
        visible: false,
        showInColumndChooser: false,
        caption: 'type',
        field: 'type',
      },
    ],
  }),
  mounted() {
    this.loadRelocationItems();
  },
  methods: {
    loadRelocationItems() {
      this.isLoading = true;
      const params = {
        sort: '-updated_at',
        page: { size: 10000 },
        status: 'O',
      };

      relocationItemsApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectedItem(e) {
      const data = e?.data;

      if (data) {
        this.$emit('on-select', data);
        this.modalOne = false;
        this.modalTwo = false;
        this.modalThree = false;
      }
    },
    onAdhoc() {
      this.$emit('on-select', {});
      this.modalOne = false;
      this.modalTwo = false;
      this.modalThree = false;
    },
  },
};
</script>

<style scoped>
.right-side {
  position: absolute;
  right: 1px;
  top: 6px;
}

.logo {
  line-height: 48px;
}

.logo img {
  vertical-align: middle;
  margin: 0 5px;
}

.logo img:first-child {
  margin-right: 9px;
}

.dx-row.dx-data-row .employee {
  color: #bf4e6a;
  font-weight: bold;
}

#gridContainer {
  /* margin: 20px 0; */
  height: 440x;
}
</style>
