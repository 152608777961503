import api from '@apiInstance';

export default {
  add (data) {
    return api.post('timelines', data);
  },
  get (id) {
    return api.fetch('timelines/' + id);
  },
  list (params) {
    return api.fetch('/timelines', { params });
  },
  update (data) {
    return api.patch('timelines', data);
  },
  delete (id) {
    return api.remove('timelines', id);
  }
};
