export default [
  {
    path: '/pages/dev-tools/notification-templates',
    name: 'notification-templates',
    component: () =>
      import('@/views/pages/dev-tools/notification-templates/index'),
    meta: {
      resource: 'NotificationTemplates', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/dev-tools/announcement-templates',
    name: 'announcement-templates',
    component: () =>
      import('@/views/pages/dev-tools/announcement-templates/index'),
    meta: {
      resource: 'AnnouncementTemplates', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/dev-tools/pdf-designers',
    name: 'pdf-designers',
    component: () => import('@/views/pages/dev-tools/pdf-designers/index'),
    meta: {
      resource: 'PdfDesigners', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/dev-tools/report-designer',
    name: 'report-designer',
    component: () =>
      import('@/views/pages/dev-tools/report-designer/ReportDesigner'),
    meta: {
      resource: 'ReportDesigner', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/dev-tools/report-designer/manage',
    name: 'report-designer-manage',
    component: () => import('@/views/pages/dev-tools/report-designer/Manage'),
    meta: {
      resource: 'ReportDesigner', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/dev-tools/report-designer/view',
    name: 'report-designer-view',
    component: () => import('@/views/pages/dev-tools/report-designer/FullView'),
    meta: {
      resource: 'ReportDesigner', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/dev-tools/checklists',
    name: 'checklists',
    component: () => import('@/views/pages/dev-tools/checklists/index'),
    meta: {
      resource: 'Checklists', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/dev-tools/checklists/manage',
    name: 'checklists-manage',
    component: () => import('@/views/pages/dev-tools/checklists/manage'),
    meta: {
      resource: 'Checklists', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/dev-tools/checklists/view',
    name: 'checklists-view',
    component: () => import('@/views/pages/dev-tools/checklists/view'),
    meta: {
      resource: 'Checklists', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/dev-tools/ticket-number',
    name: 'ticket-number',
    component: () => import('@/views/pages/dev-tools/ticket-number/index'),
    meta: {
      resource: 'Checklists', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
];
