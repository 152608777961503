<template>
  <span>
    <b-row>
      <b-col>
        <b-form-group>
          <DxDataGrid
            key-expr="id"
            :data-source="dataSource"
            :focused-row-enabled="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-borders="true"
            :row-alternation-enabled="true"
            :word-wrap-enabled="isShowingMore"
            @init-new-row="handleEvent('init-new-row', $event)"
            @option-changed="handleEvent('option-changed', $event)"
            @row-removed="handleEvent('row-removed', $event)"
            @row-click="handleEvent('row-click', $event)"
          >
            <DxPaging :page-size="totalCount" />

            <DxEditing
              :allow-updating="true"
              :allow-deleting="true"
              :use-icons="true"
              mode="row"
            />

            <DxColumnChooser :enabled="true" />
            <DxColumnFixing :enabled="true" />
            <DxSorting mode="multiple" />
            <DxFilterRow :visible="true" />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="false" />
            <DxHeaderFilter :visible="true" />
            <DxSearchPanel location="before" :visible="true" />

            <DxColumn
              v-for="(item, index) in dataColumns"
              :key="index"
              :data-field="item.field"
              :caption="item.caption"
              :cell-template="item.cellTemplate"
              :width="item.width"
            />

            <DxColumn :width="120" type="buttons">
              <DxButton icon="edit" :onClick="editItem" />
              <DxButton icon="copy" :onClick="onRevise" />
              <DxButton icon="file" :onClick="onFileView" />

              <DxButton name="delete" />
            </DxColumn>

            <DxToolbar>
              <DxItem location="before" name="columnChooserButton" />
              <DxItem location="before" name="searchPanel" />
              <DxItem name="groupPanel" />

              <DxItem template="addButtonTemplate" />
            </DxToolbar>

            <template #addButtonTemplate>
              <div>
                <b-button
                  variant="primary"
                  class="text-capitalize"
                  @click="addModal = true"
                >
                  Add
                </b-button>
              </div>
            </template>

            <template #cellDescriptionTemplate="{ data }">
              <span v-if="data.value">
                <span>
                  {{ onEllipsis(data.value) }}
                  <span
                    class="text-primary cursor-pointer"
                    @click="showMoreText"
                  >
                    show {{ isShowingMore ? 'less' : 'more' }}...
                  </span>
                </span>
              </span>
            </template>
          </DxDataGrid>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-nav>
          <b-nav-item
            @click="onSetPage(page)"
            v-for="(page, index) in pageSize"
            :key="index"
          >
            <span :class="page.isActive ? 'text-success' : ''">
              {{ page.name }}
            </span>
          </b-nav-item>
        </b-nav>
      </b-col>
      <b-col>
        <b-pagination
          align="right"
          v-model="currentPage"
          :total-rows="totalCount"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        />
      </b-col>
    </b-row>

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Document"
      @hide="closeModal()"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <h2>Document</h2>
        </b-col>
      </b-row>

      <b-row v-if="formData.name">
        <b-col cols="12" md="6">
          <b-form-group label="Name" label-for="v-name">
            <b-form-input disabled id="v-name" v-model="formData.name" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Uploaded by" label-for="v-user_id">
            <b-form-input disabled id="v-user_id" v-model="formData.user_id" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="File Type" label-for="v-file_type">
            <b-form-select
              id="v-file_type"
              v-model="formData.file_type"
              :options="documentTypes"
              @change="onSelectFileType($event)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Revision No." label-for="v-revision_no">
            <b-form-input
              id="v-revision_no"
              class="text-right"
              v-model="formData.to_revise"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label="Description" label-for="v-description">
            <b-form-textarea
              id="v-description"
              v-model="formData.description"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Upload File" label-for="v-file-upload">
            <b-form-file
              ref="formFileRef"
              v-model="onDocumentUpload"
              :accept="acceptedFile"
              placeholder="Choose/Drop a file here..."
              drop-placeholder="Drop a file here..."
              id="v-file-upload"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import documentsApi from '@api/documents';
import documentTypesApi from '@api/document_types';
import { queryParameters } from '@/schema';

export default {
  name: 'Documents',
  props: {
    module: {
      type: String,
      default: '',
    },
    headerId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    onDocumentUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.formData.name = file.name;
          this.formData.file = file.name;
          this.formData.base64 = reader.result;
        };
      }
    },
    page(v) {
      this.loadDocuments();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadDocuments();
      }
    },
    currentPage(v) {
      this.page = v;
    },
    headerId(id) {
      if (id) {
        this.loadDocuments();
      }
    },
  },
  data: () => ({
    dataIndex: -1,
    currentPage: 1,
    perPage: 0,
    totalCount: 0,
    page: 0,
    size: 0,
    pageSize: [
      { name: '10', value: 10, isActive: false },
      { name: '20', value: 20, isActive: false },
      { name: '50', value: 50, isActive: false },
      { name: '100', value: 100, isActive: false },
      { name: 'ALL', value: 10000, isActive: false },
    ],
    addModal: false,
    onDocumentUpload: null,
    dataSource: [],
    documentTypes: [],
    acceptedFile: '*',
    dataColumns: [
      {
        caption: 'Description',
        field: 'description',
        cellTemplate: 'cellDescriptionTemplate',
      },
      { caption: 'File Type', field: 'file_type', width: 100 },
      { caption: 'Upload Date', field: 'upload_date', width: 200 },
      { caption: 'Upload By', field: 'user_id', width: 100 },
      { caption: 'Revision No.', field: 'revision_no', width: 200 },
    ],
    isShowingMore: false,
    formData: {
      id: 0,
      header_id: 0,
      name: null,
      icon: null,
      file: null,
      file_type: null,
      revision_no: 1,
      to_revise: 1,
      description: null,
      status: 'O',

      base64: null,
    },
  }),
  mounted() {
    this.onSetPage({ value: queryParameters.page.size });
    this.loadDocumentTypes();
  },
  methods: {
    loadDocuments() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `header_id=${this.headerId},module='${this.module}'`,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      if (this.headerId) {
        documentsApi
          .list(params)
          .then(({ data, meta }) => {
            for (const item of data) {
              item.upload_date = this.displayDateTimeFromUnix(item.updated_at);
            }

            this.dataSource = data;

            this.totalCount = meta.page.total;
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    loadDocumentTypes() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      documentTypesApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            const { name, file_type, extension, icon } = item;
            items.push({
              text: `${name} - ${file_type}`,
              value: file_type,
              extension,
              icon,
            });
          }

          this.documentTypes = items;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        header_id: 0,
        name: null,
        icon: null,
        file: null,
        file_type: null,
        revision_no: 1,
        to_revise: 1,
        description: null,
        status: 'O',

        base64: null,
      };

      this.dataIndex = -1;
      this.onFileRemove();
      this.formData = defaultForm;
    },
    handleEvent(action, event) {
      if (action === 'row-removed') {
        documentsApi
          .delete(event.key)
          .then(({ data }) => {
            this.toastConfig('Delete Successfully');
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }

      if (action === 'row-click') {
        console.log(event);
      }
    },
    editItem(event) {
      if (event) {
        this.addModal = true;

        const data = event.row.data;
        data.to_revise = data.revision_no;

        this.dataIndex = event.row.dataIndex;
        this.formData = data;
      }
    },
    onSubmit() {
      let app = null;

      this.formData.header_id = parseInt(this.headerId);
      this.formData.revision_no = parseFloat(this.formData.to_revise);
      this.formData.module = this.module;

      if (this.dataIndex > 0) {
        app = documentsApi.update(this.formData);
      } else {
        if (this.formData.to_revise > 0) {
          delete this.formData.id;
          delete this.formData.user_id;
          delete this.formData.created_at;
          delete this.formData.updated_at;
        }

        app = documentsApi.add(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.closeModal();
            this.loadDocuments();
            this.toastConfig();
          })
          .catch(({ errors }) => {
            const [err] = errors;

            this.toastConfig(err.title, 'error');
          })
          .finally(() => {
            //
          });
      }
    },
    onSelectFileType(event) {
      const selectedFileType = this.documentTypes.find((x) => {
        return x.value === event;
      });

      if (selectedFileType) {
        const { icon, extension } = selectedFileType;
        this.acceptedFile = extension;
        this.formData.icon = icon;
      }
    },
    onFileRemove() {
      // this.onDocumentUpload = null;
    },
    onRevise(event) {
      if (event) {
        this.addModal = true;
        this.dataIndex = -1;
        const data = event.row.data;

        this.formData = data;
      }
    },
    onSetPage(event) {
      for (let i = 0; i < this.pageSize.length; i++) {
        const page = this.pageSize[i];

        page.isActive = event.value === page.value;
      }

      this.size = event.value;
      this.perPage = event.value;
    },
    showMoreText(event) {
      event.stopPropagation();

      this.isShowingMore = !this.isShowingMore;
    },
    onFileView(event) {
      const { data } = event.row;

      const staticURL = process.env.VUE_APP_ROOT_STATIC_URL;
      const url = `${staticURL}/documents/${data.id}/${data.file}`;
      window.open(url, '_blank');
    },
    onEllipsis(text) {
      if (text.length > 25 && !this.isShowingMore) {
        return `${text.substring(0, 25)}...`;
      }

      return text;
    },
  },
};
</script>

<style lang="scss"></style>
