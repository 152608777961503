export default [
  {
    path: '/pages/banners/header-banners',
    name: 'header-banners',
    component: () => import('@/views/pages/banners/header-banners/index'),
    meta: {
      resource: 'HeaderBanners', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/banners/section-banners',
    name: 'section-banners',
    component: () => import('@/views/pages/banners/section-banners/index'),
    meta: {
      resource: 'SectionBanners', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/banners/menu-banners',
    name: 'menu-banners',
    component: () => import('@/views/pages/banners/menu-banners/index'),
    meta: {
      resource: 'MenuBanners', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
];
