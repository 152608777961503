import api from '@apiInstance';

export default {
  add(types) {
    return api.post('notes', types);
  },
  get(id) {
    return api.fetch(`notes/${id}`);
  },
  list(params) {
    return api.fetch('/notes', { params });
  },
  update(types) {
    return api.patch('notes', types);
  },
  delete(id) {
    return api.remove('notes', id);
  },
};
