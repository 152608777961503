<template>
  <span>
    <b-card
      :class="`cursor-pointer ${onGetStatusColor} ${onHighlight}`"
      @click="onCenter()"
    >
      <b-row>
        <b-col class="text-right">
          <b-avatar
            :class="hasEmployeeTwo ? 'mr-1' : ''"
            v-if="hasEmployeeOne"
            size="sm"
            :src="onGetEmployeeOne.image"
            :title="onGetEmployeeOne.display_name"
          />

          <b-avatar
            v-if="hasEmployeeTwo"
            size="sm"
            :src="onGetEmployeeTwo.image"
            :title="onGetEmployeeTwo.display_name"
          />

          <b-avatar
            v-if="!hasEmployeeOne && !hasEmployeeTwo"
            size="sm"
            variant="warning"
            title="All"
          >
            <feather-icon icon="StarIcon" size="12" />
          </b-avatar>
        </b-col>
      </b-row>

      <b-row>
        <b-col :title="dataSource.ticket_no">
          {{ dataSource.ticket_no }} ({{ onGetServiceLocation }})
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="">
            <strong>
              {{ dataSource.company_name }}
            </strong>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          {{ dataSource.job_type }}
        </b-col>
      </b-row>

      <b-row>
        <b-col :title="dataSource.problem_description">
          {{ onEllipsis(dataSource.problem_description) }}
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          {{ onGetJobETA }}
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-right">
          <b-button-group size="sm">
            <b-button
              @click="onViewDetail(dataSource.id)"
              variant="outline-info"
              title="View Job"
            >
              <feather-icon icon="EyeIcon" size="16" />
            </b-button>
            <b-button
              @click="onEdit(dataSource.id)"
              variant="outline-primary"
              title="Edit"
            >
              <feather-icon icon="EditIcon" size="16" />
            </b-button>
            <b-button
              variant="outline-warning"
              title="Void Job"
              @click="onArchive()"
            >
              <feather-icon icon="ArchiveIcon" size="16" />
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-card>
  </span>
</template>

<script>
import jobOrdersApi from '@api/joborder_headers';

export default {
  name: 'JobCardComponent',
  props: {
    dataSource: {
      type: Object,
      default: () => {},
    },
    highlight: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
  watch: {
    dataSource(value) {
      //
    },
  },
  computed: {
    onGetStatusColor: {
      get() {
        const { job_status } = this.dataSource;
        let style = 'border-default';

        if (job_status === '0') {
          style += ' border-left-primary';
        }

        if (job_status === '1') {
          style += ' border-left-info';
        }

        if (job_status === '2') {
          style += ' border-left-warning';
        }

        if (job_status === '3') {
          style += ' border-left-danger';
        }

        if (job_status === '4') {
          style += ' border-left-success';
        }

        if (job_status === '5') {
          style += ' border-left-secondary';
        }

        return style;
      },
    },
    onGetServiceLocation: {
      get() {
        return this.dataSource.service_location === 'O' ? 'On-Site' : 'Remote';
      },
    },
    onGetJobETA: {
      get() {
        return this.displayDateTimeFromUnix(this.dataSource.job_eta);
      },
    },
    hasEmployeeOne: {
      get() {
        const { joborder_employee_one } = this.dataSource;
        if (joborder_employee_one) {
          const { data } = joborder_employee_one;
          return data ?? null;
        }
      },
    },
    onGetEmployeeOne: {
      get() {
        const { joborder_employee_one } = this.dataSource;
        if (joborder_employee_one) {
          const { data } = joborder_employee_one;
          if (data) {
            const staticURL = process.env.VUE_APP_ROOT_STATIC_URL;
            data.image = `${staticURL}/users/${data.header_id}/${data.thumbnail}`;

            return data;
          }
        }
      },
    },
    hasEmployeeTwo: {
      get() {
        const { joborder_employee_two } = this.dataSource;
        if (joborder_employee_two) {
          const { data } = joborder_employee_two;
          return data ?? null;
        }
      },
    },
    onGetEmployeeTwo: {
      get() {
        const { joborder_employee_two } = this.dataSource;
        if (joborder_employee_two) {
          const { data } = joborder_employee_two;
          if (data) {
            const staticURL = process.env.VUE_APP_ROOT_STATIC_URL;
            data.image = `${staticURL}/users/${data.header_id}/${data.thumbnail}`;

            return data;
          }
        }
      },
    },
    onHighlight: {
      get() {
        const { uuid } = this.dataSource;

        if (uuid === this.highlight) {
          return 'border-highlight';
        }
      },
    },
  },
  methods: {
    onViewDetail(id) {
      const url = `/pages/services-tools/job-order/view?id=${id}`;
      this.$router.push(url);
    },
    onEdit(id) {
      const url = `/pages/services-tools/job-order/manage?id=${id}`;
      this.$router.push(url);
    },
    onArchive() {
      jobOrdersApi
        .delete(this.dataSource.id)
        .then(({ data }) => {
          this.toastConfig();
          this.$emit('on-save-job', true);
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onCenter() {
      this.$emit('form-data', this.dataSource);

      const { latitude: lat, longitude: lng, marker } = this.dataSource;
      if (lat && lng) {
        this.$emit('on-center', { center: { lat, lng }, marker });
      }
    },
    onEllipsis(text) {
      if (text.length > 25) {
        return `${text.substring(0, 25)}...`;
      }

      return text;
    },
  },
};
</script>

<style>
.card-body {
  padding: 1rem !important;
}

.border-default {
  border-right: 1px solid #ebe9f1 !important;
  border-top: 1px solid #ebe9f1 !important;
  border-bottom: 1px solid #ebe9f1 !important;
}

.border-left-primary {
  border-left: 5px solid #007bff !important;
}

.border-left-info {
  border-left: 5px solid #00cfe8 !important;
}

.border-left-warning {
  border-left: 5px solid #ff9f43 !important;
}

.border-left-secondary {
  border-left: 5px solid #82868b !important;
}

.border-left-success {
  border-left: 5px solid #28c76f !important;
}

.border-highlight {
  border-right: 2px solid #343a40 !important;
  border-top: 2px solid #343a40 !important;
  border-bottom: 2px solid #343a40 !important;
}
</style>
