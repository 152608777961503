import Kitsu from 'kitsu';
import authApi from '@api/auth';
import { parseError } from '@/global-functions';
import { getUserData } from '@/auth/utils';

const kitsuIns = new Kitsu({
  baseURL: process.env.VUE_APP_ROOT_API_URL,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  camelCaseTypes: false,
  axiosOptions: {
    withCredentials: true,
  },
});

const createInterceptor = () => {
  const interceptor = kitsuIns.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      const errors = parseError(err);

      if (
        errors[0] &&
        errors[0].status === '401' &&
        errors[0].code === 'INVALID_TOKEN'
      ) {
        kitsuIns.interceptors.request.eject(interceptor);

        const userData = getUserData();
        const { token, refresh_token, id } = userData;

        return authApi
          .refreshToken(token, refresh_token, parseInt(id))
          .then((r) => {
            authApi.setToken(r.data.token);
            err.config.headers.Authorization = kitsuIns.headers.Authorization;
            return kitsuIns.axios.request(err.config);
          })
          .catch((e) => {
            if (process.browser) {
              window.localStorage.clear();
            }
            authApi.removeToken();
            return Promise.reject(err);
          })
          .finally(createInterceptor);
      }

      return Promise.reject(err);
    }
  );
};

createInterceptor();

export default kitsuIns;
